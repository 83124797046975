/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useContext } from "react";
import { Card, Button, Form, Spinner } from "react-bootstrap";
import { PasswordField, EmailField } from "components/Field";
import Auth from "@aws-amplify/auth";
import { AppContext } from "App";

function validate(values: any) {
  const errors = {} as any;
  if (values.old?.length < 8) {
    errors.old = "You must enter your old password";
  }
  if (values.new?.length < 8) {
    errors.new = "Password too short, must contain 8 or more characters";
  }
  if (
    values.new?.length > 7 &&
    // because it is just plain broken disable the following eslint rule
    // eslint-disable-next-line no-useless-escape
    !values.new.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[\^$*.\[\]{}\(\)?\-"!@#%&/,><\':;|_~`a-zA-Z0-9]{8,99}$/)
  ) {
    errors.new = "Password must contain at least one uppercase letter, one lowercase letter and one number";
  }
  if (values.new !== values.confirm) {
    errors.confirm = "Passwords do not match";
  }
  return errors;
}

export function ChangePasswordCard() {
  const { user } = useContext(AppContext);
  const [isSaving, setIsSaving] = useState(false);
  const [values, setValues] = useState({ old: "", new: "", confirm: "" });
  const [errors, setErrors] = useState<{ [K in string]: string }>({});
  const [result, setResult] = useState<string>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    const newValues = { ...values, [id]: value };
    setValues(newValues);
    if (errors[id]) {
      const error = validate(newValues);
      setErrors({ ...errors, [id]: error[id] });
    }
  };

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isSaving) return;
    const { id } = event.target;
    const error = validate(values);
    setErrors({ ...errors, [id]: error[id] });
  };

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event?.preventDefault();
    if (user === undefined) return;

    const anyErrors = validate(values);
    setErrors(anyErrors);

    if (Object.keys(anyErrors).length) {
      setIsSaving(false);
    } else {
      setIsSaving(true);
      Auth.changePassword(user.cognitoUser, values.old, values.new)
        .then((value) => {
          setValues({ old: "", new: "", confirm: "" });
          setIsSaving(false);
          setErrors({});
          setResult("Password changed successfully!");
          return value;
        })
        .catch((error) => {
          setIsSaving(false);
          setErrors({});
          setResult(error.message);
        });
    }
  }

  const allGood = isSaving && Object.keys(errors).length === 0;

  const { email } = user ?? {};

  return (
    <Card>
      <Card.Header>Change Password</Card.Header>
      <Card.Body>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <EmailField>
            <Form.Control
              name="email"
              type="text"
              onChange={handleChange}
              value={email}
              aria-label="email"
              readOnly={true}
            />
          </EmailField>
        </Form.Group>
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group controlId="old">
            <Form.Label>Old Password</Form.Label>
            <PasswordField>
              <Form.Control
                type="password"
                placeholder="Enter your old password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.old}
                isInvalid={!!errors.old}
                isValid={allGood}
              />
              <Form.Control.Feedback type="invalid">{errors.old}</Form.Control.Feedback>
            </PasswordField>
          </Form.Group>
          <Form.Group controlId="new">
            <Form.Label>New Password</Form.Label>
            <PasswordField>
              <Form.Control
                type="password"
                placeholder="New password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.new}
                isInvalid={!!errors.new}
                isValid={allGood}
              />
              <Form.Control.Feedback type="invalid">{errors.new}</Form.Control.Feedback>
            </PasswordField>
          </Form.Group>
          <Form.Group controlId="confirm">
            <PasswordField>
              <Form.Control
                type="password"
                placeholder="Confirm password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirm}
                isInvalid={!!errors.confirm}
                isValid={allGood}
              />
              <Form.Control.Feedback type="invalid">{errors.confirm}</Form.Control.Feedback>
            </PasswordField>
          </Form.Group>
          <Button type="submit" disabled={allGood}>
            Change Password {allGood && <Spinner animation="border" size="sm" className="mx-auto align-self-center" />}
          </Button>
          <Form.Group className="mt-2 mb-0">
            <span className="text-muted">{result}</span>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
}
