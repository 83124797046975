import React, { useContext, useEffect } from "react";
import { AppContext } from "App";
import { Redirect, useHistory } from "react-router-dom";
import { useAuthenticator } from "components/Authenticator";
import { queryCache } from "react-query";

export function Logout() {
  const { signOut } = useAuthenticator((context) => [context.route]);
  const { setUser } = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    const logout = async () => {
      try {
        // Clear the cache so no user data is shown when logging in as another account
        // Note: We use `clear` instead of `invalidateQueries` because some of the map layers
        //       rely on the cache even when no hook-based cache connections are active,
        //       so we need to actually clear the data, not just mark the queries as stale.
        queryCache.clear();
        setUser?.(undefined);
        signOut();
      } catch (err) {
        console.error(err);
      }
    };

    logout();
    history.push("/");
  }, [history, setUser, signOut]);

  return <Redirect to="/" />;
}

export default Logout;
