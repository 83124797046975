import React from "react";
import styled from "styled-components/macro";
import VersionInfo from "components/layout/Version";

const StyledFooter = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 25px;
  font-size: smaller;
  border-top: 1px solid #c8ced3;
  padding: 0 1rem;
  margin-top: auto;
  background: #f5f5f5;
  color: #6c757d;

  span:first-child {
    flex: 1;
    flex-basis: 0;
    display: flex;
    justify-content: flex-start;
    font-size: x-small;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  span:last-child {
    flex: 1;
    flex-basis: 0;
    display: flex;
    justify-content: flex-end;
    font-size: x-small;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export function Footer() {
  return (
    <StyledFooter>
      <span>&copy; {new Date().getFullYear()} Signal4D, Inc</span>
      <span></span>
      <VersionInfo />
    </StyledFooter>
  );
}

export default Footer;
