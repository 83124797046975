import React from "react";
import moment from "moment";
import preval from "preval.macro";
import GitInfo from "react-git-info/macro";

const buildTimestamp = preval`module.exports = new Date().getTime();`;

export function VersionInfo() {
  const lastUpdate = moment(buildTimestamp);
  const formattedDate = lastUpdate.format("YYMMDD.HHmm");
  const gitInfo = GitInfo();

  return (
    <span>
      {process.env.REACT_APP_VERSION}.{formattedDate}
      {gitInfo.branch !== "master" && ` ${gitInfo.branch}-${gitInfo.commit.shortHash}`}
    </span>
  );
}

export default VersionInfo;
