import ReactGA from "react-ga";
import { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "App";

export const useAnalytics = (trackingId: string | undefined) => {
  const location = useLocation();
  const { user } = useContext(AppContext);
  const { username } = user ?? {};

  useEffect(() => {
    if (!trackingId) return;

    try {
      ReactGA.initialize(trackingId, {
        testMode: process.env.NODE_ENV !== "production",
        gaOptions: {
          userId: username,
        },
      });
    } catch (error) {
      console.error("Error initializing analytics:", error);
    }
  }, [trackingId, username]);

  useEffect(() => {
    if (!trackingId) return;

    try {
      ReactGA.pageview(location.pathname);
    } catch (error) {
      console.error("Error recording pageview:", error);
    }
  }, [trackingId, location]);
};
