import EdiText from "react-editext";
import styled from "styled-components/macro";

// https://github.com/alioguzhan/react-editext
export const InlineEditField = styled(EdiText).attrs(({ fieldName }: { fieldName: string }) => {
  return {
    editOnViewClick: true,
    mainContainerClassName: "form-control",
    showButtonsOnHover: true,
    cancelOnEscape: false,
    cancelOnUnfocus: false,
    submitOnEnter: true,
    inputProps: { name: fieldName },
  };
})<{
  fieldName: string;
}>`
  padding-right: 4px;
  flex: 1;
  justify-content: space-between;
  > div {
    height: 100%;
  }
  button {
    border: none;
    padding: 0px 4px;
    /* height: 90%; */
  }
  button[editext="save-button"] {
    &:hover {
      background: lightgreen;
    }
  }
  button[editext="cancel-button"] {
    &:hover {
      background: crimson;
      color: #fff;
    }
  }
  input,
  textarea {
    padding: 0;
    border: none;
  }
  div[editext="view"] {
    width: 100%;
  }
  div[editext="view-container"],
  div[editext="edit-container"] {
    padding: 0;
    margin: 0;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
  div[editext="view-container"] > div,
  div[editext="edit-container"] > div {
    height: 100%;
    align-items: center;
    display: flex;
  }
`;
