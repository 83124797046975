import { CognitoUser } from "@aws-amplify/auth";

// enumeration of all the supported products
// should match group list at
// import groupList from "../../cdk/groupList.json";

export type AuthProduct =
  | "admins"
  | "weatheralerts"
  | "weatherforecast"
  | "weatherthreshold"
  | "traffic"
  | "firezones"
  | "route"
  | "routeintersect"
  | "devices"
  | "flights"
  | "paths";

export type UserAttributes = {
  email: string;
  emailVerified: boolean;
  company: string;
  phoneNumber: string;
  phoneNumberVerified: boolean;
  firstName: string;
  lastName: string;
  name: string;
  nickname: string;
  address: string;
  role: string;
  zoneinfo: string;
  profile: string;
};

const MIN_REFRESH_INTERVAL = 10 * 60 * 1000;

export type UserAttributeLabels = keyof typeof User.userAttributeLabels;

export class User {
  private readonly attributes: { [id: string]: any }; // eslint-disable-line @typescript-eslint/no-explicit-any
  private lastRefreshTime: Date = new Date();

  constructor(public cognitoUser: CognitoUser) {
    // get user claims from the id token
    this.attributes =
      (this.cognitoUser?.getSignInUserSession()?.isValid() &&
        this.cognitoUser?.getSignInUserSession()?.getIdToken()?.decodePayload()) ||
      {};
  }

  get username(): string {
    return this.attributes["cognito:username"];
  }
  get groups(): string[] {
    return this.attributes["cognito:groups"] || [];
  }
  get name(): string {
    return this.attributes["name"];
  }
  get email(): string {
    return this.attributes["email"];
  }
  get nickname(): string {
    return this.attributes["nickname"];
  }
  get company(): string {
    return this.attributes["custom:company"];
  }
  get address(): string {
    return this.attributes["address"]?.formatted ?? this.attributes["address"];
  }
  get profile(): string {
    return this.attributes["profile"];
  }
  get phoneNumber(): string {
    return this.attributes["phone_number"];
  }
  get zoneinfo(): string {
    return this.attributes["zoneinfo"];
  }
  get role(): string {
    return this.attributes["custom:role"];
  }
  get tenant(): string | undefined {
    return this.isAuthorized("admins") ? this.attributes["custom:tenant"] : undefined;
  }

  get userCollectionId(): string {
    return `user-${this.username}`;
  }

  isAuthorized(product: AuthProduct) {
    return this.groups.includes(`dashboard-${product}`.toLowerCase());
  }

  get isAdmin() {
    return this.isAuthorized("admins");
  }

  /**
   * Attempts to refresh the session, then calls a callback based on results.
   *
   * Callback provides two arguments: `err`, and `result`.
   *
   *   - If successful, `err` will be `undefined` and `result` will be the updated `CognitoUserSession`.
   *   - If failed, `err` will be the error, and `result` will be `undefined`.
   *
   * @param callback Function(err?: any, result?: CognitoUserSession)
   */
  refreshSession(callback: Function) {
    let msSinceLastRefresh = new Date().getTime() - this.lastRefreshTime.getTime();
    if (msSinceLastRefresh < MIN_REFRESH_INTERVAL) {
      callback(null, "refreshed recently already");
      return;
    }

    const refreshToken = this.cognitoUser?.getSignInUserSession()?.getRefreshToken();

    if (refreshToken) {
      this.cognitoUser.refreshSession(refreshToken, (err: unknown, result: unknown) => {
        this.lastRefreshTime = new Date();
        callback(err, result);
      });
    } else {
      callback("Refresh token not found", null);
    }
  }

  static getLabel(attribute: UserAttributeLabels) {
    return User.userAttributeLabels[attribute];
  }
  static userAttributeLabels = {
    name: "Name",
    nickname: "Nickname",
    company: "Company",
    address: "Address",
    profile: "Job Title",
    phoneNumber: "Phone",
    zoneinfo: "Time Zone",
  };
}

export default User;
