import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

type ErrorBoundaryProps = {
  hasError: boolean;
  error: { name: string } | null;
  newVersionAvailable: boolean;
};

export default class ErrorBoundary extends React.Component<{}, ErrorBoundaryProps> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false, error: null, newVersionAvailable: false };
  }

  static getDerivedStateFromError(error: unknown) {
    console.log(error);
    return { hasError: true, error };
  }

  componentDidCatch(error: unknown, info: unknown) {
    console.error("Error caught:", error, info);
  }

  onNewVersionDetected = () => {
    this.setState({ newVersionAvailable: true });
  };

  render() {
    const { error, hasError, newVersionAvailable } = this.state;

    return (
      <VersionChecker onNewVersionDetected={this.onNewVersionDetected}>
        {newVersionAvailable ? (
          <RefreshPromptModal
            title="New Application Version"
            message="This application has been updated, please refresh your browser to apply the update."
            show={true}></RefreshPromptModal>
        ) : hasError ? (
          error?.name === "ChunkLoadError" ? (
            <RefreshPromptModal
              title="New Application Version"
              message="This application has been updated, please refresh your browser to apply the update."
              show={true}></RefreshPromptModal>
          ) : (
            <RefreshPromptModal
              title="Application Error"
              message="The application encountered an error. Please refresh to try again."
              show={true}></RefreshPromptModal>
          )
        ) : (
          this.props.children
        )}
      </VersionChecker>
    );
  }
}

function VersionChecker(props: React.PropsWithChildren<{ onNewVersionDetected: () => void }>) {
  const [currentAssetFilename, setCurrentAssetFilename] = useState<string>("");
  const history = useHistory();

  useEffect(() => {
    /**
     * On first run, this saves the filename (including hash) of the main.js chunk.
     * On subsequent runs, it fetches the newest filename and compares it to the saved one.
     * If they are not a match, the application needs to be updated.
     */
    const checkForNewVersion = async () => {
      try {
        const response = await fetch(`${window.location.origin}/asset-manifest.json`);
        let responseBody;

        if (!response.ok) {
          const errorMessage = await response.json();
          console.error(`Error fetching asset manifest:`, errorMessage);
          throw Error(errorMessage);
        }

        responseBody = await response.json();
        const fetchedAssetFilename = responseBody.files["main.js"];

        if (currentAssetFilename.length && currentAssetFilename !== fetchedAssetFilename) {
          props.onNewVersionDetected();
        }

        setCurrentAssetFilename(fetchedAssetFilename);
      } catch (error) {
        console.error("Error checking app version via chunk hash:", error);
      }
    };
    return history.listen((location) => {
      checkForNewVersion();
    });
  }, [currentAssetFilename, history, props]);

  return props.children as React.ReactElement;
}

type ModalProps = {
  title: string;
  message: string;
  show: boolean;
};

function RefreshPromptModal(props: ModalProps) {
  const { title, message, show } = props;

  const reloadPage = () => window.location.reload();

  return (
    <Modal centered show={show}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={reloadPage}>
          Refresh
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
