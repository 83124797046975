import React from "react";
import { InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export const Field = ({ label, children }: { label: string; children?: React.ReactNode }) => (
  <InputGroup className="mb-3">
    <InputGroup.Prepend>
      <InputGroup.Text style={{ width: "100px" }}>{label}</InputGroup.Text>
    </InputGroup.Prepend>
    {children}
  </InputGroup>
);

export const PasswordField = ({ children, style }: { children?: React.ReactNode; style?: React.CSSProperties }) => (
  <IconField icon={"lock"}>{children}</IconField>
);
export const EmailField = ({ children, style }: { children?: React.ReactNode; style?: React.CSSProperties }) => (
  <IconField icon={"envelope"}>{children}</IconField>
);

export const IconField = ({
  children,
  style,
  icon,
}: {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  icon: IconProp;
}) => (
  <InputGroup className="mb-3">
    <InputGroup.Prepend>
      <InputGroup.Text>
        <FontAwesomeIcon icon={icon} className="text-secondary" />
      </InputGroup.Text>
    </InputGroup.Prepend>
    {children}
  </InputGroup>
);
