import type { Location } from "models/location";
import type { WeatherEvent, WeatherEventId } from "models/forecast";
import type { AlertReference, WeatherAlert } from "models/weatherAlert";
import type { WeatherAlertType } from "mapLayers/WeatherAlertProduct";

const FORECAST_HOURS = 12; // 12 hours of forecast then one extra added for current "zero" hour
export const forecastHours = Array.from({ length: FORECAST_HOURS + 1 }, (_, i) => i);

export function getDateFromForecastHour(hour: string | number): Date {
  const hoursFromNow = new Date(Date.now() + (+hour + 1) * 60 * 60 * 1000);
  const startOfHour = hoursFromNow.toISOString().slice(0, 13) + ":00:00.000Z";
  return new Date(Date.parse(startOfHour));
}
/*
  locations: []
    events[eventId] : array[hour]

      WeatherEvent {
        eventId: WeatherEventId;
        description: string;
        value: number;
        operator?: Operator;
        time?: Date;
        type?: WeatherType;
        forecastDate: string;
      }
*/

export type EventReference = {
  eventId: WeatherEventId;
  description?: string;
  forecasts?: {
    forecastDate: string;
    value?: number;
    maxValue?: number;
    minValue?: number;
  }[];
};

export type LocationResponse = {
  locations: LocationEvents[];
  events?: WeatherEvent[];
  alerts?: WeatherAlert[];
};

export type LocationEvents = {
  item: Location;
  events?: EventReference[];
  alerts?: AlertReference[];
  error?: string;
};

export type LocationEventsByType = {
  location: Location;
  eventsByType?: Record<WeatherEventId, Record<number, EventReference>>;
  alertsByType?: Record<WeatherAlertType, WeatherAlert[]>;
};

export type LocationEvents12Hour = {
  location: Location;
  eventsByHour: Record<number, EventReference[]>;
};

// export function firstWeatherEvent(
//   locationWeather: LocationEvents12Hour,
//   eventType: WeatherEventId,
// ): WeatherEvent | null {
//   const { eventsByHour } = locationWeather;
//   let event: WeatherEvent | null = null;

//   forecastHours.find((hour) => {
//     const events = (eventsByHour[hour] ?? []).filter((e) => e.eventId === eventType);
//     if (events.length > 0) {
//       event = { ...events[0], time: getDateFromForecastHour(hour) };
//       return true;
//     }
//     return false;
//   });

//   return event;
// }
