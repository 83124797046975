import React, { useContext } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import NavLink from "react-bootstrap/NavLink";
import NavItem from "react-bootstrap/NavItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppContext } from "App";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";

function UserCard() {
  const { user } = useContext(AppContext);
  const history = useHistory();
  const { email = "" } = user ?? {};
  const { company = "" } = user ?? {};
  const isAdmin = user?.isAdmin;
  return (
    <Dropdown as={NavItem}>
      <Dropdown.Toggle as={NavLink} id="signout-toggle">
        <FontAwesomeIcon icon="user-circle" size="lg" />
      </Dropdown.Toggle>
      <Menu alignRight>
        <Dropdown.Item onClick={() => history.push("/userprofile")}>
          User Profile
          <div className="small text-muted text-nowrap">
            Signed in as: <span className="font-weight-bold">{email}</span>
            {company === "" ? null : (
              <p>
                Company: <span className="font-weight-bold">{company}</span>
              </p>
            )}
            {isAdmin && <Dropdown.ItemText className="text-center bg-danger text-light">ADMIN</Dropdown.ItemText>}
          </div>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={() => history.push("/logout")}>Sign Out</Dropdown.Item>
      </Menu>
    </Dropdown>
  );
}

const Menu = styled(Dropdown.Menu)`
  .navbar-nav .dropdown-menu& {
    position: absolute !important;
  }
`;

export default UserCard;
