import { useQuery } from "react-query";
import { queryCache } from "react-query";
import LocationApi from "adapters/LocationApi";
import type { FetchQueryObjectConfig } from "react-query/types/core/queryCache";
import type { Collection } from "models/collection";

const query: FetchQueryObjectConfig<Collection[], unknown> = {
  queryKey: "collections",
  queryFn: () => LocationApi.fetchCollections(),
  config: {
    staleTime: 60 * 60 * 1000,
    refetchOnWindowFocus: false,
  },
};

export default function useCollections() {
  return useQuery<Collection[]>(query);
}

export function fetchCollections() {
  return queryCache.fetchQuery<Collection[]>(query);
}
