import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Amplify, { Auth } from "aws-amplify";
import { Authenticator, AuthenticatorProvider, useAuthenticator } from "components/Authenticator";
import MainSection from "components/layout/MainSection";
import { User } from "models/user";
import amplifyConfig from "amplifyConfig.js";
import { ReactQueryDevtools } from "react-query-devtools";
import { getAuthStorage } from "utils/localStorage";

const API = {
  API: {
    endpoints: [
      {
        name: "Dashboard-API",
        endpoint: `https://${amplifyConfig.ApiConfig.host}`,
        custom_header: async () => {
          return { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
        },
      },
    ],
  },
};

const authConfig = { Auth: amplifyConfig.Auth };
Amplify.configure({ ...authConfig, ...API });

Auth.configure({
  storage: getAuthStorage(),
});

export const isAW = window.location.hostname.includes("airwise");

if (process.env.NODE_ENV !== "development") console.log = () => {};
const appConfig = { ...amplifyConfig.AppConfig, icon: isAW ? "aw" : "s4d" };
export type AppContextType = {
  appConfig: {
    icon: string;
    name: string;
    mapStyle?: string;
  };
  user?: User;
  setUser?: React.Dispatch<React.SetStateAction<User | undefined>>;
};
export const AppContext = React.createContext<AppContextType>({ appConfig } as AppContextType);

const App = () => (
  <AuthenticatorProvider>
    <Router>
      <AuthOrMain />
    </Router>
  </AuthenticatorProvider>
);

const AuthOrMain = () => {
  const { route, user: amplifyUser } = useAuthenticator((context) => [context.route]);
  const [user, setUser] = useState<User | undefined>(amplifyUser ? new User(amplifyUser) : undefined);

  useEffect(() => {
    console.log("useEffect, setting user", amplifyUser);
    amplifyUser && setUser(new User(amplifyUser));
  }, [amplifyUser]);

  console.log(route, user, amplifyUser);
  return route === "authenticated" ? (
    <AppContext.Provider value={{ appConfig, user, setUser }}>
      <MainSection />
      <ReactQueryDevtools initialIsOpen={false} /> {/* This only gets loaded in development */}
    </AppContext.Provider>
  ) : (
    <Switch>
      <Route path="/terms">Terms</Route>
      <Route path="*">
        <Authenticator />
      </Route>
    </Switch>
  );
};
export default App;
