import weatherAlertProductsByPhenomena from "weatherAlertProductsByPhenomena.json";

export type WeatherAlertType = "severe" | "flood" | "winter" | "tropical" | "other";

export type WeatherAlertProduct = {
  id: string;
  productCode: string;
  color: string;
};
export const weatherAlertProducts: Record<WeatherAlertType, WeatherAlertProduct> = {
  severe: { id: "weather-severe", productCode: "643", color: "#ff0000" },
  flood: { id: "weather-flood", productCode: "644", color: "#35af6d" },
  winter: { id: "weather-winter", productCode: "645", color: "#0000ff" },
  tropical: { id: "weather-tropical", productCode: "647", color: "#ffa500" },
  other: { id: "weather-other", productCode: "646", color: "#c0c0c0" },
};

export const lookupProductByPhenomena = (phenomenaCode: string): WeatherAlertType => {
  return (weatherAlertProductsByPhenomena as Record<string, WeatherAlertType>)[phenomenaCode] ?? "other";
};
