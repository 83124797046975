import React from "react";
import {
  Authenticator as AmplifyAuthenticator,
  Image,
  Text,
  Button,
  Heading,
  useAuthenticator,
  useTheme,
  View,
  CheckboxField,
  TextField,
  Link,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import navLogo from "images/nav-logo.png";
import awLogo from "images/aw-logo-w.png";
import "components/Authenticator.css";
import { isAW } from "App";

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image alt="Amplify logo" src={isAW ? awLogo : navLogo} />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={`${tokens.colors.neutral["80"]}`}>
          &copy; {new Date().getFullYear()} {isAW ? "AirWise Solutions & Signal4D" : "Signal4D"}, Inc. All Rights
          Reserved
        </Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
          Sign in to your account
        </Heading>
      );
    },
  },

  SignUp: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
          Create a new account
        </Heading>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button fontWeight="normal" onClick={toSignIn} size="small" variation="link">
            Back to Sign In
          </Button>
        </View>
      );
    },
    FormFields() {
      const { validationErrors } = useAuthenticator();

      return (
        <>
          {/* Re-use default `Authenticator.SignUp.FormFields` */}
          <AmplifyAuthenticator.SignUp.FormFields />
          <TextField label="Company" name="custom:company" placeholder="Company" required={true} />
          {/* Append & require Terms & Conditions field to sign up  */}
          <CheckboxField
            errorMessage={validationErrors.acknowledgement}
            hasError={!!validationErrors.acknowledgement}
            name="acknowledgement"
            value="yes"
            label="I agree with the Terms & Conditions"
          />
          <Link href="/terms" isExternal={true}>
            Terms & Conditions
          </Link>
        </>
      );
    },
  },
  ConfirmSignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <Button fontWeight="normal" onClick={toSignIn} size="small" variation="link">
          Back to Sign In
        </Button>
      );
    },
  },
};

const services = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async validateCustomSignUp(formData: any) {
    if (!formData.acknowledgement) {
      return {
        acknowledgement: "You must agree to the Terms & Conditions",
      };
    }
  },
};

const Authenticator = () => {
  return (
    <AmplifyAuthenticator
      loginMechanisms={["email"]}
      signUpAttributes={["name", "email", "phone_number"]}
      components={components}
      services={services}
    />
  );
};

const AuthenticatorProvider = AmplifyAuthenticator.Provider;

export { Authenticator, AuthenticatorProvider, useAuthenticator };
