import React, { useCallback, useContext, useEffect } from "react";
import { FormControl, Card, Spinner } from "react-bootstrap";
import Auth from "@aws-amplify/auth";

import { Field } from "components/Field";
import { keys } from "utils";
import { InlineEditField } from "components/InlineEditField";
import User from "models/user";
import { AppContext } from "App";

export type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export type OnSaveFn = (value: string, inputProps: InputProps) => void;

export function UserProfileCard() {
  const { user, setUser } = useContext(AppContext);
  const isAdmin = user?.isAdmin;

  // force a refresh of the cached user details
  const refreshUser = useCallback(async () => {
    try {
      const userData = await Auth.currentAuthenticatedUser({ bypassCache: true });
      userData && setUser?.(new User(userData));
    } catch (err) {
      console.error(err);
    }
  }, [setUser]);

  // save any changes and then refresh the amplify cache
  const onSave = (value: string, inputProps: InputProps) => {
    if (user === undefined) return;

    const { name = "" } = inputProps;
    const updatedAttribute = { [name]: value };

    Auth.updateUserAttributes(user.cognitoUser, updatedAttribute)
      .then(() => refreshUser())
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    refreshUser();
  }, [refreshUser]);

  return (
    <Card>
      <Card.Header>Profile</Card.Header>
      {user?.email ? (
        <Card.Body>
          <Field label="Email">
            <FormControl name="email" value={user.email} aria-label="email" readOnly={true} />
          </Field>

          {keys(User.userAttributeLabels).map((f) => (
            <Field label={User.userAttributeLabels[f]} key={User.userAttributeLabels[f]}>
              <InlineEditField
                value={user[f]}
                onSave={onSave}
                type={f === "phoneNumber" ? "tel" : "text"}
                fieldName={f === "company" ? "custom:company" : f}
                key={f}
              />
            </Field>
          ))}

          {isAdmin && (
            <Field label="Tenant">
              <InlineEditField value={user?.tenant ?? ""} onSave={onSave} type="text" fieldName="custom:tenant" />
            </Field>
          )}
        </Card.Body>
      ) : (
        <Card.Body className="justify-content-center d-flex">
          <Spinner animation="border" className="my-auto mx-auto" />
        </Card.Body>
      )}
    </Card>
  );
}
