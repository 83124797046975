export * from "./keys";
export * from "./types";
export * from "./text";

export function variableWidth(targetWidth: number, targetZoom: number) {
  return {
    type: "exponential",
    base: 2,
    stops: [
      [0, targetWidth * Math.pow(2, 0 - targetZoom)],
      [24, targetWidth * Math.pow(2, 24 - targetZoom)],
    ],
  };
}
