import React, { useContext } from "react";
import { Link } from "react-router-dom";
import useCollections from "../hooks/useCollections";
import { Container } from "react-bootstrap";
import { LoadingSpinner } from "icon-library";
import { CollectionWeatherSummary } from "components/CollectionWeatherSummary";
import { AppContext } from "App";
import { Collection } from "models/collection";

export function DashboardPage() {
  const { status: collectionsStatus, data: collections } = useCollections();
  const { user } = useContext(AppContext);

  const myCollections =
    //collections?.filter((c) => c.collectionId === user?.userCollectionId) ||
    [{ collectionId: user!.userCollectionId, collectionName: "My Saved Places" }];

  let collectionsToShow = collections?.filter((c) => c.showOn?.includes("dashboard")) ?? [];

  if (collectionsToShow.length === 0) {
    collectionsToShow =
      collections?.filter((c) => [user?.userCollectionId, "stores", "stadiums"].includes(c.collectionId)) ?? [];
  }

  return (
    <Container className="py-4">
      {collectionsStatus === "loading" ? (
        <div className="text-center">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <MyCollections collections={myCollections} />
          <SharedCollections collections={collectionsToShow} />
        </>
      )}
    </Container>
  );
}

function MyCollections({ collections }: { collections: Collection[] }) {
  console.log(collections);
  return collections?.length ? (
    <>
      {/* {collections.map((collection) => ( */}
      {/* <div key={`collection-${collection.collectionId}-weather-summary`}> */}
      <CollectionWeatherSummary
        collection={collections[0]}
        eventIds={["temperatureLow", "temperatureHigh", "highWind", "highGusts", "snow", "heavyRain"]}
      />
      {/* </div> 
       ))}*/}
    </>
  ) : (
    <div className="text-center my-4">
      <p className="text-secondary">
        No Saved Locations found. You can create Saved Locations on the <Link to="/map">Map Page</Link>
      </p>
    </div>
  );
}
function SharedCollections({ collections }: { collections: Collection[] }) {
  return collections?.length ? (
    <>
      {collections.map((collection) => (
        <div key={`collection-${collection.collectionId}-weather-summary`}>
          <CollectionWeatherSummary
            collection={collection}
            eventIds={["temperatureLow", "temperatureHigh", "highWind", "highGusts", "snow", "heavyRain"]}
          />
        </div>
      ))}
    </>
  ) : (
    <div className="text-center">
      <p className="text-secondary">
        Please visit the <Link to="/map">Map Page</Link> to create Saved Locations and see live weather and traffic
        conditions.
      </p>
    </div>
  );
}

export default DashboardPage;
