import { useQuery } from "react-query";
import LocationApi, { CollectionType } from "adapters/LocationApi";
import { forecastHours, LocationEventsByType, LocationResponse } from "models/locationEvents";
import { WeatherType } from "models/forecast";

function combineLocationWeatherForecasts(
  arrayOfLocationWeatherForecasts: LocationResponse[],
): Record<string, LocationEventsByType> {
  const combinedForecastsByObjectId: Record<string, LocationEventsByType> = {};

  arrayOfLocationWeatherForecasts.forEach((locationEvents, forecastHour) => {
    locationEvents?.locations?.forEach((locationForecast) => {
      const { item: location, events } = locationForecast;
      const { objectId } = location;

      if (!combinedForecastsByObjectId[objectId]) {
        combinedForecastsByObjectId[objectId] = { location, eventsByType: {} } as LocationEventsByType;
      }

      const { eventsByType } = combinedForecastsByObjectId[objectId];
      events?.forEach((event) => {
        if (!eventsByType) return; // never happens, just to satisfy Typescript
        eventsByType![event.eventId] = eventsByType![event.eventId] ?? {};
        eventsByType![event.eventId][forecastHour] = event;
      });
      // const updatedEventsByType = { ...eventsByType, [forecastHour]: events ?? [] };
      // combinedForecastsByObjectId[objectId].eventsByHour = updatedEventsByHour;
    });
  });

  return combinedForecastsByObjectId;
}

export function useCollectionWeatherForecast(
  collectionId: string,
  weatherTypes: WeatherType[],
  collectionType?: CollectionType,
) {
  return useQuery(
    ["collectionsWeatherForecast", collectionId, weatherTypes],
    (): Promise<Record<string, LocationEventsByType>> => {
      return new Promise((resolve, reject) => {
        // call the API for each forecast hour in the request
        const promises: Promise<LocationResponse>[] = forecastHours.map((forecastHour) => {
          return LocationApi.fetchWeatherForecastForCollection(collectionId, {
            forecastHours: [forecastHour],
            weatherTypes,
            collectionType,
          });
        });

        Promise.all(promises)
          .then((locationWeatherForecasts) => {
            const combinedLocationWeather = combineLocationWeatherForecasts(locationWeatherForecasts);
            return resolve(combinedLocationWeather);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    {
      staleTime: 5 * 60 * 1000, // 5 min
      refetchOnWindowFocus: false,
      enabled: true,
      retry: false,
    },
  );
}

export default useCollectionWeatherForecast;
