import React, { SVGProps } from "react";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import { MapLayerType } from "mapLayers/mapLayerProducts";
import { WeatherEventId } from "models/forecast";
import { ReactComponent as TxWXThresholds } from "assets/tx_WXThresholds.svg";
import { ReactComponent as TxWXAlerts } from "assets/tx_WXAlerts.svg";
import { ReactComponent as TxTFIncidents } from "assets/tx_TFIncidents.svg";
import { ReactComponent as TxTFConstruction } from "assets/tx_TFConstruction.svg";
import { ReactComponent as TxTFCurrent } from "assets/tx_TFCurrent.svg";
import { ReactComponent as TxTFTraffic } from "assets/tx_TFPredictive.svg";
import { ReactComponent as TxWXFlood } from "assets/tx_WXFlood.svg";
import { ReactComponent as TxWXOther } from "assets/tx_WXOther.svg";
import { ReactComponent as AQI } from "assets/aqi.svg";
import { ReactComponent as TxWXTornado } from "assets/tx_WXTornado.svg";
import { ReactComponent as TxWXTropical } from "assets/tx_WXTropical.svg";
import { ReactComponent as TxWXWinter } from "assets/tx_WXWinter.svg";
import { ReactComponent as TxWXFreezing24Hrs } from "assets/tx_freezing24hrs.svg";
import { ReactComponent as TxFreezingNow } from "assets/tx_FreezingNow.svg";
import { ReactComponent as TxSnowLastHour } from "assets/tx_SnowLastHour.svg";
import { ReactComponent as TxSnowFuture } from "assets/tx_SnowFuture.svg";
import { ReactComponent as TxRainFuture } from "assets/tx_RainFuture.svg";
import { ReactComponent as TxWindFuture } from "assets/tx_WindFuture.svg";
import { ReactComponent as TxFreezingFuture } from "assets/tx_FreezingFuture.svg";
import { ReactComponent as TxFireAlerts } from "assets/tx_WXFireAlerts.svg";
import { ReactComponent as WxRain } from "assets/rain.svg";
import { ReactComponent as WxSnow } from "assets/snow.svg";
import { ReactComponent as WxTempHigh } from "assets/highTemp.svg";
import { ReactComponent as WxTempLow } from "assets/temp.svg";
import { ReactComponent as WxWind } from "assets/wind.svg";
import { ReactComponent as WxWindGusts } from "assets/windGusts.svg";
import { ReactComponent as WxFlood } from "assets/wxFlood.svg";
import { ReactComponent as WxTornado } from "assets/wxTornado.svg";
import { ReactComponent as WxWinter } from "assets/wxWinter.svg";
import { ReactComponent as WxTropical } from "assets/wxTropical.svg";
import { ReactComponent as WxOther } from "assets/wxOther.svg";
import { ReactComponent as FxDroneRadar } from "assets/droneRadar.svg";
import { ReactComponent as FxDrone } from "assets/drone2.svg";
import { ReactComponent as UtilityPole } from "assets/utility pole.svg";
import { ReactComponent as UtilityPoleDouble } from "assets/utility pole double.svg";
import mapIconTornadoAlert from "assets/tile_WXTornado.svg";
import mapIconFloodAlert from "assets/tile_WXFlood.svg";
import mapIconWinterAlert from "assets/tile_WXWinter.svg";
import mapIconTropicalAlert from "assets/tile_WXTropical.svg";
import mapIconOtherAlert from "assets/tile_WXOther.svg";
// import mapIconFreezing24hrs from "assets/tile_freezing24hrs.svg";
import mapIconFreezingNow from "assets/tile_FreezingNow.svg";
import mapIconHighTemp from "assets/highTemp.svg";
// import mapIconSnowLastHour from "assets/tile_SnowLastHour.svg";
import mapIconSnowFuture from "assets/tile_WXSnow.svg";
import mapIconRainFuture from "assets/tile_RainFuture.svg";
import mapIconWindFuture from "assets/tile_WXWind.svg";
// import mapIconFreezingFuture from "assets/tile_FreezingFuture.svg";
import { ReactComponent as TxStore } from "assets/tx_store.svg";
import { ReactComponent as TxStadium } from "assets/tx_stadium.svg";
import { ReactComponent as TxTruck } from "assets/truck_solid_icon.svg";
import { ReactComponent as TxBus } from "assets/bus_icon.svg";
import { ReactComponent as TxTrain } from "assets/train_icon.svg";
import truckMapIcon, { ReactComponent as truckPopupIcon } from "assets/truck_map.svg";
import storeMapIcon, { ReactComponent as storePopupIcon } from "assets/store_map.svg";
import stadiumMapIcon, { ReactComponent as stadiumPopupIcon } from "assets/stadium_map.svg";
import busMapIcon, { ReactComponent as busPopupIcon } from "assets/bus_map.svg";
import trainMapIcon, { ReactComponent as trainPopupIcon } from "assets/train_map.svg";
import locationMapIcon, { ReactComponent as locationPopupIcon } from "assets/location_map.svg";

/** SOLID ICONS (PREFIX: fas (default)) */
import {
  faUserSlash,
  faHome,
  faUserCog,
  faUserCircle,
  faAngleRight,
  faAngleDown,
  faExclamationTriangle,
  faExclamationCircle,
  faHistory,
  faStore,
  faCheck,
  faSyncAlt,
  faTrash,
  faCircleNotch,
  faFlagCheckered,
  faMapMarkerAlt,
  faSnowflake,
  faWind,
  faClock,
  faTemperatureLow,
  faBorderAll,
  faSquare,
  faCheckSquare,
  faMapMarker,
  faMap,
  faChartBar,
  faRoute,
  faWindowClose,
  faLock,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

import { library, IconProp } from "@fortawesome/fontawesome-svg-core";
import { AqiLayer } from "mapLayers/aqiLayer";
library.add(
  faLock,
  faEnvelope,
  faSquare,
  faCheckSquare,
  faClock,
  faHistory,
  faWindowClose,
  faUserCircle, // header
  faAngleRight, // drowndown menus
  faAngleDown,
  faBorderAll,
  // sidebar
  faHome,
  faChartBar,
  faRoute,
  faMap,
  faExclamationTriangle,
  faExclamationCircle,
  faUserSlash,
  faUserCog,
  // weather sub menu
  faSnowflake,
  faWind,
  faTemperatureLow,
  // store
  faStore,
  faMapMarker,
  // routes page
  faCheck,
  faSyncAlt,
  faTrash,
  faCircleNotch,
  faMapMarkerAlt,
  faFlagCheckered,
);

type FontAwesomePropsNoIcon = Omit<FontAwesomeIconProps, "icon">;

export const HourIcon = (props: FontAwesomePropsNoIcon) => (
  <FontAwesomeIcon icon={"clock"} transform="left-4" {...props} />
);
export const DayIcon = (props: FontAwesomePropsNoIcon) => (
  <FontAwesomeIcon icon={"calendar"} transform="left-4" {...props} />
);
export const MinutesIcon = (props: FontAwesomePropsNoIcon) => (
  <FontAwesomeIcon icon="history" transform="left=4" {...props} />
);
export const RefreshIcon = (props: FontAwesomePropsNoIcon) => (
  <FontAwesomeIcon icon="sync-alt" transform="left-4" {...props} />
);
export const CheckIcon = (props: FontAwesomePropsNoIcon) => (
  <FontAwesomeIcon icon="check" transform="left-4" {...props} />
);
export const SquareIcon = (props: FontAwesomePropsNoIcon) => (
  <FontAwesomeIcon icon={"square"} transform="left-4" {...props} />
);
export const CheckSquareIcon = (props: FontAwesomePropsNoIcon) => (
  <FontAwesomeIcon icon={"check-square"} transform="left-4" {...props} />
);
export const FlagCheckeredIcon = (props: FontAwesomePropsNoIcon) => (
  <FontAwesomeIcon icon={"flag-checkered"} transform="left-4" {...props} />
);
export const MapMarkerAltIcon = (props: FontAwesomePropsNoIcon) => (
  <FontAwesomeIcon icon={"map-marker-alt"} transform="left-4" {...props} />
);
export const StoppedIcon = (props: FontAwesomePropsNoIcon) => (
  <FontAwesomeIcon icon="ban" transform="left-4" {...props} />
);
export const TrashIcon = (props: FontAwesomePropsNoIcon) => <FontAwesomeIcon icon={"trash"} {...props} />;
export const ExclamationTriangleIcon = (props: FontAwesomePropsNoIcon) => (
  <FontAwesomeIcon icon={"exclamation-triangle"} transform="left-4" {...props} />
);
export const SyncAltIcon = (props: FontAwesomePropsNoIcon) => (
  <FontAwesomeIcon icon={"sync-alt"} transform="left-4" {...props} />
);
export const LoadingSpinner = (props: FontAwesomePropsNoIcon) => (
  <FontAwesomeIcon icon={"circle-notch"} spin {...props} />
);
export const AngleDownIcon = (props: FontAwesomePropsNoIcon) => <FontAwesomeIcon icon={"angle-down"} {...props} />;

/**
 * Add custom SVG icons into this array after importing them above.
 * Follow these three steps, not the casing on the icon name examples...
 * 1. Add: import { ReactComponent as IconName } from "assets/iconFile.svg";
 * 2. Add "iconName" to S4dIcons array
 * 3. Add: "iconName": props => <IconName {...props} />,
 */
export const S4dIconElements = {
  txWXAlerts: TxWXAlerts,
  txWXThresholds: TxWXThresholds,
  txTFIncidents: TxTFIncidents,
  txTFConstruction: TxTFConstruction,
  txTFCurrent: TxTFCurrent,
  txTFTraffic: TxTFTraffic,
  txWXFlood: TxWXFlood,
  txWXOther: TxWXOther,
  txWXTornado: TxWXTornado,
  txWXTropical: TxWXTropical,
  txWXWinter: TxWXWinter,
  txWXFreezing24Hrs: TxWXFreezing24Hrs,
  txFreezingNow: TxFreezingNow,
  fire: TxFireAlerts,
  txStadium: TxStadium,
  txStore: TxStore,
  txTruck: TxTruck,
  txBus: TxBus,
  txTrain: TxTrain,
  txSnowLastHour: TxSnowLastHour,
  txSnowFuture: TxSnowFuture,
  txRainFuture: TxRainFuture,
  txWindFuture: TxWindFuture,
  txFreezingFuture: TxFreezingFuture,
  wxRain: WxRain,
  wxSnow: WxSnow,
  wxTempHigh: WxTempHigh,
  wxTempLow: WxTempLow,
  wxWind: WxWind,
  wxWindGusts: WxWindGusts,
  wxFlood: WxFlood,
  wxTornado: WxTornado,
  wxWinter: WxWinter,
  wxTropical: WxTropical,
  wxOther: WxOther,
  fxDroneRadar: FxDroneRadar,
  fxDrone: FxDrone,
  utilityPole: UtilityPole,
  utilityPoleDouble: UtilityPoleDouble,
  aqi: AQI,
};

type S4dIconProp = keyof typeof S4dIconElements;
export type Icon = S4dIconProp | IconProp;
export type SVGIcon = React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;

export function S4dIcon(props: { icon: Icon } & FontAwesomePropsNoIcon) {
  const { icon, fixedWidth, ...rest } = props;
  if (typeof icon === "string" && Object.keys(S4dIconElements).includes(icon as S4dIconProp)) {
    return React.createElement(S4dIconElements[icon as S4dIconProp], { ...(rest as SVGProps<SVGSVGElement>) });
  } else {
    return <FontAwesomeIcon icon={icon as IconProp} fixedWidth={fixedWidth} {...rest} />;
  }
}

export const productIcons: Record<
  MapLayerType | "store" | "truck" | "bus" | "train" | "location" | "route" | "flightPaths" | "stadium",
  { solidIcon: string | [IconPrefix, IconName]; tileIcon?: string }
> = {
  severe: { solidIcon: "txWXTornado", tileIcon: mapIconTornadoAlert },
  flood: { solidIcon: "txWXFlood", tileIcon: mapIconFloodAlert },
  winter: { solidIcon: "txWXWinter", tileIcon: mapIconWinterAlert },
  tropical: { solidIcon: "txWXTropical", tileIcon: mapIconTropicalAlert },
  other: { solidIcon: "txWXOther", tileIcon: mapIconOtherAlert },
  // freezing24: { solidIcon: "txWXFreezing24Hrs", tileIcon: mapIconFreezing24hrs },
  // freezingNow: { solidIcon: "txFreezingNow", tileIcon: mapIconFreezingNow },
  over30now: { solidIcon: "wxTempHigh", tileIcon: mapIconHighTemp },
  snow: { solidIcon: "wxSnow", tileIcon: mapIconSnowFuture },
  rain: { solidIcon: "wxRain", tileIcon: mapIconRainFuture },
  highWind: { solidIcon: "wxWind", tileIcon: mapIconWindFuture },
  freezingFuture: { solidIcon: "txFreezingNow", tileIcon: mapIconFreezingNow },
  traffic: { solidIcon: "txTFCurrent" },
  incidents: { solidIcon: "txTFIncidents" },
  construction: { solidIcon: "txTFConstruction" },
  fireSat: { solidIcon: "fire" },
  fireNifc: { solidIcon: "fire" },
  FAA_NationalSecurity: { solidIcon: "exclamation-circle" },
  FAA_Facilities: { solidIcon: "exclamation-circle" },
  store: { solidIcon: "txStore" },
  stadium: { solidIcon: "txStadium" },
  truck: { solidIcon: "txTruck" },
  bus: { solidIcon: "txBus" },
  train: { solidIcon: "txTrain" },
  location: { solidIcon: "map-marker-alt" },
  route: { solidIcon: "route" },
  flightPaths: { solidIcon: "fxDrone" },
  powergrid: { solidIcon: "utilityPoleDouble" },
  powerlines: { solidIcon: "utilityPole" },
  railways: { solidIcon: "route" },
  maritime: { solidIcon: "route" },
  aqi: { solidIcon: "aqi" },
};

export const weatherThresholdIcons: Record<WeatherEventId, { popupIcon: string | [IconPrefix, IconName] }> = {
  snow: { popupIcon: "wxSnow" },
  temperatureHigh: { popupIcon: "wxTempHigh" },
  temperatureLow: { popupIcon: "txFreezingNow" },
  heavyRain: { popupIcon: "wxRain" },
  highGusts: { popupIcon: "wxWind" },
  highWind: { popupIcon: "wxWind" },
};

export type CollectionIcon = "store" | "truck" | "bus" | "train" | "location" | "stadium";

export interface CollectionIconInfo {
  map: string;
  popup: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  anchor: "center" | "bottom";
}

export const CollectionIcons: Record<CollectionIcon, CollectionIconInfo> = {
  truck: { map: truckMapIcon, popup: truckPopupIcon, anchor: "center" },
  store: { map: storeMapIcon, popup: storePopupIcon, anchor: "center" },
  stadium: { map: stadiumMapIcon, popup: stadiumPopupIcon, anchor: "center" },
  bus: { map: busMapIcon, popup: busPopupIcon, anchor: "center" },
  train: { map: trainMapIcon, popup: trainPopupIcon, anchor: "center" },
  location: { map: locationMapIcon, popup: locationPopupIcon, anchor: "bottom" },
};
