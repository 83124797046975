import { Auth } from "aws-amplify";
import amplifyConfig from "amplifyConfig.js";

const API_BASE_URL = `https://${amplifyConfig.ApiConfig.host}`;
const API_KEY = process.env.REACT_APP_S4D_API_KEY!;

export async function makeRequest({
  method,
  path,
  authType,
  query,
  body,
  baseUrl,
  headers: extraHeaders,
}: {
  method: "GET" | "POST" | "PUT" | "DELETE" | "OPTIONS";
  path: string;
  authType: "apiKey" | "oauth" | "none";
  query?: Record<string, string | undefined>;
  body?: unknown;
  baseUrl?: string;
  headers?: Record<string, string>;
}) {
  try {
    let url = `${removeSlash(baseUrl ?? API_BASE_URL)}${path}`;

    if (query) {
      url += `?${Object.entries(query)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => `${key}=${value}`)
        .join("&")}`;
    }

    const headers = new Headers();
    if (extraHeaders) {
      Object.entries(extraHeaders).forEach(([key, value]) => headers.append(key, value));
    }

    if (authType === "apiKey") {
      headers.append("x-api-key", API_KEY);
    } else if (authType === "oauth") {
      const token = (await Auth.currentSession()).getIdToken().getJwtToken();
      headers.set("Authorization", token);
    } else if (authType === "none") {
      // do nothing, this expects that it is being handled via query or body at call time
    }

    const fetchOptions = { method, headers, body: JSON.stringify(body) };
    console.log(JSON.stringify(fetchOptions));
    const response = await fetch(url, fetchOptions);
    let responseBody;

    if (!response.ok) {
      if (response.status === 401 && authType === "oauth") {
        window.location.href = "/logout";
      }

      const errorMessage = await response.json();
      if (response.status !== 404) {
        console.error(`API ${response.status} error:`, errorMessage);
      }
      throw new Error(errorMessage);
    }

    if (response.status === 204) {
      responseBody = "";
    } else {
      responseBody = await response.json();
    }

    return responseBody;
  } catch (err) {
    throw err;
  }
}

/**
 * Remove trailing slashes from path.
 * Unless optional second parameter is set to false, the function will leave a lone slash ("/") as that is
 * a legal file path. Otherwise, all trailing slashes will be removed.
 *
 * Example:
 *  removeSlash("/1/2")   == "/1/2"
 *  removeSlash("/1/2/")  == "/1/2"
 *  removeSlash("/1/2//") == "/1/2"
 *  removeSlash("/")      == "/"
 *
 * @param {string} path
 * @param {boolean} [keepInitial=true]
 * @returns
 */
function removeSlash(path: string, keepInitial = true) {
  if (!path) return "";
  return keepInitial ? path.replace(/^(.+?)\/*?$/, "$1") : path.replace(/\/+$/, "");
}
