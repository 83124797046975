import React from "react";
import { Button } from "react-bootstrap";
import { SyncAltIcon } from "icon-library";

export const CollectionWeatherTableHeader = ({
  collectionName: title,
  lastUpdatedAt,
  onClickRefresh,
  updating = false,
  children,
}: {
  collectionName: string;
  lastUpdatedAt: Date;
  onClickRefresh: () => Promise<void>;
  updating?: boolean;
  children?: React.ReactNode;
}) => {
  return (
    <header className="card-header bg-info">
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <h4 className="text-white">{title}</h4>
        </div>
        <div className="d-flex align-items-center">
          <small className="mr-4">
            {updating ? "Updating..." : `Last updated at ${lastUpdatedAt.toLocaleTimeString()}`}
          </small>
          <Button
            title="Refresh data"
            variant="outline-info"
            size="sm"
            className="mr-2 bg-light"
            onClick={onClickRefresh}
            disabled={updating}>
            <SyncAltIcon transform="" spin={updating} />
          </Button>
        </div>
      </div>
      {children}
    </header>
  );
};
