/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

export const ModalContext = React.createContext<{
  showModal: (component: React.ElementType, props?: any) => void;
  hideModal: (component: React.ElementType, props?: any) => void;
}>({ showModal: () => {}, hideModal: () => {} });

interface ModalState {
  component: React.ElementType | null;
  props?: any;
}

export function ModalProvider(modalProps: { children: React.ReactNode }) {
  const [state, setState] = React.useState<ModalState>({ component: null, props: {} });
  const [scrollPosition, setScrollPosition] = React.useState<number>(0);
  const main = document.querySelector("#main");

  const showModal = (component: React.ElementType, props = {}) => {
    if (!main) return;
    console.log(modalProps, props);
    setScrollPosition(main.scrollTop); // When the Modal gets opened, the scroll position is saved correctly
    setState({ component, props });
  };

  const hideModal = () => {
    setState({ component: null, props: {} });
    setTimeout(() => {
      if (main) main.scrollTop = scrollPosition; // Restoring doesn't work.
    });
  };

  const { component: ModalComponent, props } = state;
  return (
    <ModalContext.Provider
      value={{
        showModal,
        hideModal,
      }}>
      {modalProps.children}
      {ModalComponent && <ModalComponent {...props} onClose={hideModal} />}
    </ModalContext.Provider>
  );
}
