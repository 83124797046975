import React, { useContext, useRef, Suspense } from "react";
import styled from "styled-components/macro";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { AppContext } from "App";
import { Footer } from "components/layout/Footer";
import { Sidebar } from "components/layout/Sidebar";
import Header from "components/layout/Header";
import { sidebarWidth, headerHeight, breakpoint } from "components/layout/constants";
import { useAuthRouteList } from "routes";
import { Spinner } from "react-bootstrap";
import ErrorBoundary from "components/ErrorBoundary";
import { useAnalytics } from "hooks/useAnalytics";
import { ModalProvider } from "components/Modal";

function MainSection() {
  const { user } = useContext(AppContext);
  const { groups = [], email } = user ?? {};

  const history = useHistory();
  // Attempt to refresh session when navigating, redirect to login if failed
  history.listen((location, action) => {
    // prevent infinite loop
    if (location.pathname === "/logout") return;

    user?.refreshSession((err: unknown, result: unknown) => {
      if (err) {
        if (location.pathname === "/logout") return;
        console.log("refresh token expired, redirecting to login...");
        alert("Your session has expired, please log in again.");
        history.push("/logout");
      }
    });
  });

  useAnalytics(process.env.REACT_APP_GA_TRACKING_ID);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const toggleSidebar = () => {
    wrapperRef.current?.classList.toggle("SidebarOpen");
  };

  return (
    <ErrorBoundary>
      <Wrapper className="bg-light" ref={wrapperRef}>
        <Header {...{ groups, email, toggleSidebar }} />
        <Sidebar toggleSidebar={toggleSidebar} />
        <MainPage />
        <Footer />
      </Wrapper>
    </ErrorBoundary>
  );
}

function MainPage() {
  const routeList = useAuthRouteList();
  const location = useLocation();
  // The rest of your rendering logic
  return (
    <Main id="main">
      <ModalProvider>
        <Switch>
          {routeList.map(({ page: Page, ...props }, key) => {
            return (
              <Route
                {...props}
                key={key}
                render={() => (
                  <Suspense fallback={<MiddleSpinner />}>
                    <Page location={location} {...props} />
                  </Suspense>
                )}
              />
            );
          })}
        </Switch>
      </ModalProvider>
    </Main>
  );
}

const MiddleSpinner = () => (
  <Middle>
    <Spinner animation="border" />
  </Middle>
);
const Middle = styled.div`
  top: 50%;
  position: absolute;
  left: 50%;
  margin-top: -1rem;
  margin-left: -1rem;
`;

const Wrapper = styled.div`
  transition-duration: 0.25s;
  transition-property: padding, flex;
  padding-left: ${sidebarWidth};

  @media (max-width: ${breakpoint}) {
    padding-left: 0px;

    &.SidebarOpen {
      padding-left: ${sidebarWidth};
    }
  }
  height: 100%; /*calc(100% - ${headerHeight})*/
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

const Main = styled.main`
  flex: 1 1;
  min-width: 0;
  overflow-x: hidden;
  position: relative;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

export default MainSection;
