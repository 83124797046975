import React, { useContext } from "react";
import styled from "styled-components/macro";
import { AppContext } from "App";
import { breakpoint } from "components/layout/constants";
import logo from "images/signal4d.png";
import aw from "images/aw.svg";
import navLogo from "images/nav-logo.png";
import awLogo from "images/aw-logo-w.png";

export function Home() {
  const { appConfig, user } = useContext(AppContext);

  const { groups = [] } = user ?? {};
  return (
    <HomePage>
      {appConfig.icon === "aw" ? (
        <>
          <AwIcon />
          <AwImage />
        </>
      ) : (
        <Image />
      )}
      <Title className="">{appConfig.name}</Title>
      {groups.length ? (
        <Hint className="navbar-dark SB-Hint">
          Choose from the menu by pressing <br />
          the <span className=" navbar-toggler-icon"></span> button in the header bar.
        </Hint>
      ) : (
        <Hint className="text-warning">
          Thank you for signing up. <br />
          You will receive an email when your account has been approved.
        </Hint>
      )}
    </HomePage>
  );
}

export default Home;

const Image = styled.img`
  width: 75%;
  max-width: 400px;
  z-index: 0;
  content: url(${logo});
  @media (max-height: 500px) {
    content: url(${navLogo});
    width: 50%;
  }
`;
const AwIcon = styled.img`
  width: 10%;
  max-width: 200px;
  padding-bottom: 30px;
  z-index: 0;
  content: url(${aw});
  @media (max-height: 500px) {
    padding-bottom: unset;
    display: none;
  }
`;

const AwImage = styled.img`
  width: 50%;
  max-width: 400px;
  padding-bottom: 30px;
  z-index: 0;
  content: url(${awLogo});
  @media (max-height: 500px) {
    padding-bottom: unset;
  }
`;

const Title = styled.div`
  font-size: 13vw;
  margin-top: -30px;
  @media (max-height: 500px) {
    margin-top: -10px;
  }
  z-index: 1;
  @media screen and (max-width: 720px) {
    /* font-size: 48px; */
  }
  @media screen and (min-width: 550px) {
    font-size: 72px;
  }
`;

const Hint = styled.div`
  text-align: center;
  padding: 20px 10px;

  @media (min-height: 500px) {
    font-size: large;
  }

  &.SB-Hint {
    display: none;
    @media (max-width: ${breakpoint}) {
      display: block;
    }
  }

  .SidebarOpen & {
    display: none;
  }
`;

const HomePage = styled.div`
  @media (min-height: 500px) {
    padding-bottom: 50px;
  }
  height: 100%;
  background-color: rgb(1, 1, 52);
  color: #fff;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
`;
