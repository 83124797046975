import { makeRequest } from "adapters/makeRequest";
import { Route, optimizeRouteForRequest } from "models/route";
import type { Location } from "models/location";
import type { Collection } from "models/collection";
import type { LocationResponse } from "models/locationEvents";
import type { RouteResponse, CollectionResponse } from "models/routeResponse";
import polyline from "@mapbox/polyline";
import type { WeatherType } from "models/forecast";

const BASE_PATH = "/locations/oa";

export interface PaginationProps {
  /** The maximum number of records per page requested */
  limit?: number;

  /** The last ID after which we want the next 'page' to start */
  after?: string;
}

export interface ForecastOptions {
  forecastHours: number[];
  weatherTypes: WeatherType[];
  includeData?: boolean;
}

export type CollectionType = "routes" | "objects";
export interface CollectionOptions {
  collectionType?: CollectionType;
}

class LocationApi {
  static async fetchCollections(): Promise<Collection[]> {
    const collections = await makeRequest({
      method: "GET",
      authType: "oauth",
      path: `${BASE_PATH}/v2/collections`,
    });
    return collections?.length ? collections : [];
  }

  static async fetchLocationObjects(collectionId?: string): Promise<Location[]> {
    const locations = await makeRequest({
      method: "GET",
      authType: "oauth",
      path: `${BASE_PATH}/v2/objects`,
      query: collectionId ? { collectionId } : {},
    });
    return locations?.length ? locations : [];
  }

  static async fetchWeatherForecastForLocations(
    objectIds: string[],
    forecastOptions: ForecastOptions,
  ): Promise<LocationResponse> {
    if (!objectIds || objectIds.length <= 0) throw new Error("objectIds are required");

    let locationResponse: LocationResponse = { locations: [], events: [] };

    // some endpoints support multiple forecast hours, this one does not (yet)
    const forecastHour = forecastOptions.forecastHours.length > 0 ? forecastOptions.forecastHours[0] : 0;

    const query: Record<string, string> = { forecastHour: `${forecastHour}` };
    if (forecastOptions.weatherTypes.length > 0) query.weatherTypes = forecastOptions.weatherTypes.join(",");

    try {
      const responseBody = await makeRequest({
        method: "GET",
        authType: "oauth",
        path: `${BASE_PATH}/v2/objects/${objectIds.join(",")}/weather/forecast`,
        query,
      });

      if (responseBody !== "") {
        locationResponse = responseBody;
      }
    } catch (error) {}

    return locationResponse;
  }

  static async fetchWeatherForecastForCollection(
    collectionId: string,
    options: ForecastOptions & CollectionOptions,
  ): Promise<CollectionResponse> {
    if (!collectionId) throw new Error("collectionId is required");

    let collectionResponse: CollectionResponse = { locations: [], routes: [], events: [] };

    const query: Record<string, string> = { forecastHour: `${options.forecastHours.join(",")}` };
    if (options.weatherTypes.length > 0) query.weatherTypes = options.weatherTypes.join(",");
    if (options.includeData !== undefined) query.includeData = options.includeData.toString();
    if (options.collectionType !== undefined) query.collectionType = options.collectionType;

    try {
      const responseBody = await makeRequest({
        method: "GET",
        authType: "oauth",
        path: `${BASE_PATH}/v2/collections/${collectionId}/weather/forecast`,
        query,
      });

      if (responseBody !== "") {
        collectionResponse = responseBody;
      }
    } catch (error) {
      // console.error(`Failed to fetch forecast hour ${forecastOptions.forecastHour} for ${collectionId}`);
    }

    return collectionResponse;
  }

  static async fetchWeatherAlertsForCollection(
    collectionId: string,
    options: CollectionOptions,
  ): Promise<LocationResponse> {
    if (!collectionId) throw new Error("collectionId is required");

    let locationResponse: LocationResponse = { locations: [], alerts: [] };

    const query: Record<string, string> = {};
    if (options.collectionType) query.collectionType = options.collectionType;

    try {
      const responseBody = await makeRequest({
        method: "GET",
        authType: "oauth",
        path: `${BASE_PATH}/v2/collections/${collectionId}/weather/alerts`,
        query,
      });

      if (responseBody !== "") {
        locationResponse = responseBody;
      }
    } catch (error) {
      // console.error(`Failed to fetch alerts for collectionID "${collectionId}"`);
    }

    return locationResponse;
  }

  static async fetchRoutes(collectionId?: string, pageProps?: PaginationProps): Promise<Route[]> {
    const routes: Route[] = await makeRequest({
      method: "GET",
      authType: "oauth",
      path: `${BASE_PATH}/v2/routes`,
      query: {
        ...(collectionId && { collectionId }),
        ...(pageProps?.limit && { limit: pageProps.limit.toString() }),
        ...(pageProps?.after && { after: pageProps.after }),
      },
    });
    // convert the route geometry now so we only do it once
    if (routes.length) {
      routes.forEach((r) => {
        const route = r.routes[0];
        if (!route.lineString) {
          route.lineString = { type: "Feature", properties: {}, geometry: polyline.toGeoJSON(route.geometry) };
        }
      });
    }
    return routes?.length ? routes : [];
  }

  static async fetchRoute(routeId: string): Promise<Route | undefined> {
    const route = await makeRequest({
      method: "GET",
      authType: "oauth",
      path: `${BASE_PATH}/v2/routes/${routeId}`,
    });
    return route?.length ? route : undefined;
  }

  static async createRoute(routeProps: Route, echoResult?: boolean): Promise<undefined | Route> {
    const optimizedRouteProps = optimizeRouteForRequest(routeProps);
    return await makeRequest({
      method: "POST",
      authType: "oauth",
      path: `${BASE_PATH}/v2/routes`,
      body: optimizedRouteProps,
      query: { echo: echoResult?.toString() },
    });
  }

  static async createObject(object: Partial<Location>, echoResult?: boolean): Promise<undefined | Location> {
    return await makeRequest({
      method: "POST",
      authType: "oauth",
      path: `${BASE_PATH}/v2/objects`,
      body: object,
      query: { echo: echoResult?.toString() },
    });
  }

  static async updateRoute(route: Route): Promise<void> {
    return await makeRequest({
      method: "POST",
      authType: "oauth",
      path: `${BASE_PATH}/v2/routes`,
      body: route,
    });
  }

  static async deleteObject(objectId: string): Promise<void> {
    return await makeRequest({
      method: "DELETE",
      authType: "oauth",
      path: `${BASE_PATH}/v2/objects/${objectId}`,
    });
  }

  static async deleteRoute(routeId: string): Promise<void> {
    return await makeRequest({
      method: "DELETE",
      authType: "oauth",
      path: `${BASE_PATH}/v2/routes/${routeId}`,
    });
  }

  static async fetchWeatherAlertsForRoute(routeId: string): Promise<RouteResponse> {
    if (routeId.length === 0) throw new Error("routeId is required");

    let routeResponse: RouteResponse = { routes: [], alerts: [] };
    try {
      routeResponse = await makeRequest({
        method: "GET",
        authType: "oauth",
        path: `${BASE_PATH}/v2/routes/${routeId}/weather/alerts`,
      });
    } catch (error) {}
    return routeResponse;
  }

  static async fetchWeatherForecastForRoute(routeId: string, forecastOptions: ForecastOptions): Promise<RouteResponse> {
    if (!routeId || routeId.length === 0) throw new Error("Missing routeId");

    // some endpoints support multiple forecast hours, this one does not (yet)
    const forecastHours = forecastOptions.forecastHours.toString();

    const query: Record<string, string> = { forecastHour: `${forecastHours}` };
    if (forecastOptions.weatherTypes.length > 0) query.weatherTypes = forecastOptions.weatherTypes.join(",");
    if (forecastOptions.includeData !== undefined) query.includeData = `${forecastOptions.includeData}`;

    let routeResponse: RouteResponse = { routes: [], events: [] };
    try {
      routeResponse = await makeRequest({
        method: "GET",
        authType: "oauth",
        path: `${BASE_PATH}/v2/routes/${routeId}/weather/forecast`,
        query,
      });
    } catch (error) {}
    return routeResponse;
  }
}

export default LocationApi;
