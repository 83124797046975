export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
}
export function titleCase(str?: string): string {
  return str?.replace(/([^\W_]+[^\s-]*) */g, capitalize) ?? "";
}

export function sentenceCase(str: string): string {
  return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
}
