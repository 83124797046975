import React, { useState } from "react";
import { useRouteMatch, NavLink } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";
import { RouteType } from "routes";
import { S4dIcon } from "icon-library";

export type SidebarItemType = {
  onClick?: () => void;
  toggleSidebar?: Function;
  className?: string;
  startOpen?: boolean;
} & Omit<RouteType, "page">;

export const SidebarItem = (props: React.PropsWithChildren<SidebarItemType>) => {
  const { icon, name, path, exact, subMenu, toggleSidebar, className, children, startOpen = true } = props;
  const [showMenu, setShowMenu] = useState(startOpen);
  const match = useRouteMatch({ path, exact: true });
  const droppable = !!match || !!children;
  const handleClick = () => {
    if (droppable && subMenu) {
      setShowMenu(!showMenu);
    } else {
      toggleSidebar?.(false);
    }
    props.onClick?.();
  };

  const Link = path ? NavLink : "a";
  return (
    <>
      <Link to={path} exact={exact} onClick={handleClick} className={(subMenu ? "DropDown " : "") + className}>
        <SidebarIcon icon={icon} />
        <SidebarLabel label={name} />
        {subMenu ? <DropDownIcon rotation={droppable && showMenu ? 90 : undefined} /> : children}
      </Link>
      {subMenu && (
        <Collapse in={droppable && showMenu} appear={true}>
          <div className="SubMenu" id={subMenu}>
            {children}
          </div>
        </Collapse>
      )}
    </>
  );
};

function SidebarLabel({ label }: { label: string }) {
  return <span className="SidebarLabel text-uppercase">{label}</span>;
}

const DropDownIcon = styled(FontAwesomeIcon).attrs((props) => ({
  size: "2x",
  icon: "angle-right",
}))`
  margin-left: auto;
  transition-duration: 0.3s;
  transition-property: transform;
`;

const SidebarIcon = styled(S4dIcon).attrs((props) => ({
  size: "lg",
  fixedWidth: true,
}))`
  height: 2rem;
  width: 2rem;
  flex: 0 0 1.5rem;
  fill: white !important;
  & path {
    fill: white !important;
  }
`;
