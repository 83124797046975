import { useQuery } from "react-query";
import LocationApi, { CollectionType } from "adapters/LocationApi";
import type { LocationEventsByType } from "models/locationEvents";
import { lookupProductByPhenomena } from "mapLayers/WeatherAlertProduct";

export function useCollectionWeatherAlerts(collectionId: string, collectionType?: CollectionType) {
  return useQuery(
    ["collectionsWeatherAlerts", collectionId],
    (): Promise<Record<string, LocationEventsByType>> =>
      LocationApi.fetchWeatherAlertsForCollection(collectionId, {
        collectionType,
      }).then((locationEvents) => {
        const combinedAlertsByObjectId: Record<string, LocationEventsByType> = {};

        locationEvents?.locations?.forEach((locationAlert) => {
          const { item: location, alerts } = locationAlert;
          const { objectId } = location;

          if (!combinedAlertsByObjectId[objectId]) {
            combinedAlertsByObjectId[objectId] = { location, alertsByType: {} } as LocationEventsByType;
          }

          const { alertsByType } = combinedAlertsByObjectId[objectId];
          alerts?.forEach((alert) => {
            const alertDetail = locationEvents.alerts?.find((a) => a.detailKey === alert.detailKey);
            if (!alertDetail || !alertsByType) return;
            const alertType = lookupProductByPhenomena(alertDetail.phenomena);
            alertsByType[alertType] = alertsByType[alertType] ?? [];
            alertsByType[alertType].push(alertDetail);
          });
        });
        return combinedAlertsByObjectId;
      }),
    {
      staleTime: 5 * 60 * 1000, // 5 min
      refetchOnWindowFocus: false,
      enabled: true,
      retry: false,
    },
  );
}

export default useCollectionWeatherAlerts;
