import React from "react";
import styled from "styled-components/macro";
import Nav from "react-bootstrap/Nav";
import { SidebarItem } from "components/layout/SidebarItem";
import { useAuthRouteList } from "routes";

export function Sidebar(props: { toggleSidebar: Function }) {
  const { toggleSidebar } = props;
  const routeList = useAuthRouteList();
  return (
    <SidebarContent className=" bg-darkblue">
      <Nav color="dark" className="flex-column">
        {routeList.map((props, key) => (
          <SidebarItem {...props} key={key} toggleSidebar={toggleSidebar} />
        ))}
      </Nav>
    </SidebarContent>
  );
}

const sidebarWidth = "200px";
const breakpoint = "991px";

const SidebarContent = styled.aside`
  height: 100%;
  width: ${sidebarWidth};
  flex: 0 0 ${sidebarWidth};
  color: #fff;
  overflow-y: auto;
  position: fixed;
  transition-duration: 0.25s;
  transition-property: left, flex;
  left: 0;
  user-select: none;

  @media (max-width: ${breakpoint}) {
    left: ${"-" + sidebarWidth};

    .SidebarOpen > & {
      left: 0;
    }
  }

  .nav {
    user-select: none;
    cursor: pointer;
    width: 100%;
  }

  .SubMenu {
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
  }
  a {
    color: #f8f9fa;
    display: flex;
    border-radius: 0;
    align-items: center;
    font-size: small;
    padding: 10px 1rem;
    width: 100%;
    &.active {
      background-color: #0063cc;
    }
    &:hover {
      background: #23527b;
    }
  }
  .SidebarLabel {
    margin-left: 1rem;
  }
`;

export default Sidebar;
