import { titleCase } from "utils/text";

export type WeatherType = "temp" | "wind" | "rain" | "snow" | "gust";
export const allWeatherTypes: WeatherType[] = ["temp", "wind", "rain", "snow" /*, "gust"*/];

export type WeatherEventId = typeof weatherThresholds[number]["threshold"]["id"];

export type Operator = ">" | ">=" | "=" | "<" | "<=";

export interface WeatherEvent {
  eventId: WeatherEventId;
  description: string;
  value: number;
  operator?: Operator;
  time?: Date;
  type?: WeatherType;
  unit: string;
}

export type WeatherThreshold = typeof weatherThresholds[number];
//{
//   weatherType: WeatherType;
//   threshold: { id: string; description: string; value: number; operator: Operator };
// };

export const weatherThresholds = [
  {
    weatherType: "temp",
    threshold: {
      id: "temperatureHigh",
      description: "temp over 30°C",
      value: 30,
      operator: ">",
      unit: "°C",
      title: "High Temp",
      subtitle: "Temperatures over 30°C/86°F",
      fill: "#ff914d",
      lightFill: "#ffb16d",
      outline: "#174682",
      percentage: (value: number) => Math.min(1, (value - 30) / 20),
    },
  },
  {
    weatherType: "temp",
    threshold: {
      id: "temperatureLow",
      description: "temp below 4°C",
      value: 4,
      operator: "<=",
      unit: "°C",
      title: "Low Temp",
      subtitle: "Temperatures at or below 4°C/39°F",
      fill: "#3d5e89",
      lightFill: "#a4bdde",
      outline: "#174682",
      // temps as percentage of -30°C
      percentage: (value: number) => Math.min(1, Math.max(0, 4 - value) / 34),
    },
  },
  {
    weatherType: "rain",
    threshold: {
      id: "heavyRain",
      description: "rain over 1mm",
      value: 1,
      operator: ">",
      unit: "mm(s) per hour",
      title: "Heavy Rain",
      subtitle: "Rainfall over 1mm per hour",
      fill: "#003173",
      lightFill: "#a3caff",
      outline: "#174682",
      // rain in mm p/h as percentage of 50 mm per hour
      percentage: (value: number) => Math.min(1, value / 50),
    },
  },
  {
    weatherType: "wind",
    threshold: {
      id: "highWind",
      description: "wind over 9 m/s",
      value: 9,
      operator: ">",
      unit: "m/s",
      title: "High Wind",
      subtitle: "Sustained Wind Speed above 20mph",
      fill: "#f40",
      lightFill: "#ffbfa8",
      outline: "#A62C00",
      // wind speed in m/s as percentage of 50m/s = 111mph
      percentage: (value: number) => Math.min(1, value / 50),
    },
  },
  {
    weatherType: "gust",
    threshold: {
      id: "highGusts",
      description: "wind gusts over 9 m/s",
      value: 9,
      operator: ">",
      unit: "m/s",
      title: "Wind Gusts",
      subtitle: "High Wind Gusts above 20mph",
      fill: "#f40",
      lightFill: "#ffbfa8",
      outline: "#A62C00",
      // wind speed in m/s as percentage of 50m/s = 111mph
      percentage: (value: number) => Math.min(1, value / 50),
    },
  },
  {
    weatherType: "snow",
    threshold: {
      id: "snow",
      description: "snow over 1cm",
      value: 9,
      operator: ">",
      unit: "cm(s)",
      title: "Snowfall",
      subtitle: "Snowfall over 1cm per hour",
      fill: "#ff01bf",
      lightFill: "#ffbfef",
      outline: "#174682",
      percentage: (value: number) => Math.min(1, (value - 30) / 20),
    },
  },
] as const;

const weatherAlertTitle = (alertType: string) => {
  if (alertType === "other") {
    return {
      title: "Weather Alert",
      subtitle: "Governmental Weather alert",
    };
  } else {
    return {
      title: `${titleCase(alertType)} Alert`,
      subtitle: `Governmental ${alertType} alert`,
    };
  }
};
export const weatherText = (eventId: string) =>
  weatherThresholds.find((w) => w.threshold.id === eventId)?.threshold ?? weatherAlertTitle(eventId);

export const weatherPercentage = (eventId: WeatherEventId, value: number) =>
  weatherThresholds.find((w) => w.threshold.id === eventId)?.threshold.percentage(value);

export const weatherUnit = (eventId: string) =>
  weatherThresholds.find((w) => w.threshold.id === eventId)?.threshold.unit ?? "";

function celsiusToFahrenheit(celsius: number): number {
  return Math.round((celsius * 9) / 5 + 32);
}

function mpsToMph(mps: number): number {
  return Math.round(mps * 2.237);
}

function mmToInches(mm: number): number {
  return Math.round(mm / 25.4);
}

export function weatherTypeUnits(wt: WeatherType, metric?: boolean) {
  const convertMetric = metric ?? ["snow", "rain"].includes(wt);
  switch (wt) {
    case "wind":
      return convertMetric ? "m/s" : "mph";
    case "temp":
      return convertMetric ? "°C" : "°F";
    case "rain":
      return convertMetric ? "mm" : '"';
    case "snow":
      return convertMetric ? "cm" : '"';
    case "gust":
      return convertMetric ? "m/s" : "mph";
  }
}
export function weatherTypeValue(wt: WeatherType, value: number, metric?: boolean) {
  const convertMetric = metric ?? ["snow", "rain"].includes(wt);
  switch (wt) {
    case "wind":
      return convertMetric ? value : mpsToMph(value);
    case "temp":
      return convertMetric ? value : celsiusToFahrenheit(value);
    case "rain":
      return convertMetric ? value : mmToInches(value);
    case "snow":
      return convertMetric ? value : mmToInches(value * 10);
    case "gust":
      return convertMetric ? value : mpsToMph(value);
  }
  return value;
}

export function displayValue({
  eventId,
  weatherType,
  value,
}: {
  eventId?: string;
  weatherType?: WeatherType;
  value: number;
}) {
  const wt = weatherType ?? weatherThresholds.find((w) => w.threshold.id === eventId)?.weatherType;
  return wt ? `${weatherTypeValue(wt, value)} ${weatherTypeUnits(wt)}` : `${value}`;
}
