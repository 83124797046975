import React from "react";
import { LoadingSpinner } from "icon-library";

export const SlowLoader: React.FC<{ showSpinnerIfTrue: boolean }> = (props) => {
  return props.showSpinnerIfTrue ? (
    <div className="py-5 text-center">
      <LoadingSpinner size="2x" />
    </div>
  ) : (
    <>{props.children}</>
  );
};
