import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import UserCard from "components/UserCard";
import navLogo from "images/nav-logo.png";
import aw from "images/aw.svg";
import awLogo from "images/aw-logo-w.png";
import styled from "styled-components/macro";
import { Switch, Route } from "react-router-dom";
import { AppContext } from "App";

function Header(props: { toggleSidebar: Function }) {
  const { appConfig } = React.useContext(AppContext);
  const [isPressed, setIsPressed] = React.useState(false);
  const { toggleSidebar } = props;

  const handleSidebarControlButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    event.currentTarget.blur();
    toggleSidebar();
  };

  return (
    <Navbar as="header" expand="lg" variant="dark" className="bg-darkblue justify-content-lg-between" fixed="top">
      <Logo>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={handleSidebarControlButton} />
        <Navbar.Brand href="/">
          {appConfig.icon === "aw" ? (
            <img
              src={isPressed ? awLogo : aw}
              width="150"
              height="35"
              className="d-inline-block align-top user-select-none"
              alt="AirWise"
              onClick={(e) => {
                e.preventDefault();
                setIsPressed(!isPressed);
              }}
            />
          ) : (
            <img src={navLogo} width="150" height="35" className="d-inline-block align-top" alt="Signal4D" />
          )}
        </Navbar.Brand>
      </Logo>
      <Switch>
        <Route path="/dashboard">
          <Title className="d-none d-sm-inline"> Dashboard </Title>
        </Route>
        <Route path="/locations">
          <Title className="d-none d-sm-inline"> Locations </Title>
        </Route>
        <Route path="/map">
          <Title className="d-none d-sm-inline"> {appConfig.name} </Title>
        </Route>
        <Route path="/routes">
          <Title className="d-none d-sm-inline"> Saved Routes </Title>
        </Route>
      </Switch>
      <Nav>
        <UserCard />
      </Nav>
    </Navbar>
  );
}

const Logo = styled.div`
  @media (min-width: 992px) {
    width: 184px;
  }
`;

const Title = styled.div`
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.2;
  color: white;
  padding-left: 10px;
  margin-left: -80px;
  @media (min-width: 992px) {
    margin-left: 10px;
  }
`;

export default Header;
