import React, { useState, useCallback } from "react";
import { Collection } from "models/collection";
import { Card, CardColumns } from "react-bootstrap";
import { WeatherEventId, weatherThresholds } from "models/forecast";
import { useCollectionWeatherForecast } from "hooks/useCollectionWeatherForecast";
import { SlowLoader } from "./SlowLoader";
import { useCollectionWeatherAlerts } from "hooks/useCollectionWeatherAlerts";
import { WeatherIcon, WeatherText } from "./WeatherType";
import { CollectionWeatherTableHeader } from "./CollectionWeatherTableHeader";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";

export function CollectionWeatherSummary({
  collection,
  eventIds,
}: {
  collection: Collection;
  eventIds: WeatherEventId[];
}) {
  const { collectionId, collectionName = "default" } = collection;
  const weatherTypes = weatherThresholds.filter((w) => eventIds.includes(w.threshold.id)).map((w) => w.weatherType);

  const {
    status: forecastStatus,
    data: collectionForecasts,
    isFetching: isFetchingForecasts,
    refetch: refetchCollectionForecast,
  } = useCollectionWeatherForecast(collectionId, weatherTypes);

  const {
    status: alertStatus,
    data: collectionAlerts,
    isFetching: isFetchingAlerts,
    refetch: refetchCollectionAlerts,
  } = useCollectionWeatherAlerts(collectionId, "objects");
  const [lastUpdatedAt, setLastUpdatedAt] = useState<Date>(new Date());

  const refetch = useCallback(async () => {
    await Promise.all([refetchCollectionForecast(), refetchCollectionAlerts()]);
    setLastUpdatedAt(new Date());
  }, [refetchCollectionForecast, refetchCollectionAlerts]);

  // combine alerts and forecast events
  const combinedEventMap = { ...collectionForecasts };
  collectionAlerts &&
    Object.entries(collectionAlerts).forEach(([k, v]) => {
      combinedEventMap[k] = combinedEventMap[k] ?? { location: v.location };
      combinedEventMap[k].alertsByType = v.alertsByType;
    });

  // loop through the entire collectionForecastMap object calculating totals for each weather type
  const eventTotalsMap = new Map<string, number>();
  Object.entries(combinedEventMap ?? {}).forEach(([id, events]) => {
    Object.keys(events.eventsByType ?? {}).forEach((type) => {
      if (eventIds.includes(type as WeatherEventId)) eventTotalsMap.set(type, (eventTotalsMap.get(type) ?? 0) + 1);
    });
    Object.keys(events.alertsByType ?? {}).forEach((type) => {
      const waType = "wa-" + type;
      eventTotalsMap.set(waType, (eventTotalsMap.get(waType) ?? 0) + 1);
    });
  });
  const eventTotals: Array<[string, number]> = [...eventTotalsMap].sort().map(([et, v]) => [et.replace(/^wa-/, ""), v]);
  return (
    <Card className="shadow-sm mt-3">
      <CollectionWeatherTableHeader
        collectionName={collectionName}
        lastUpdatedAt={lastUpdatedAt}
        onClickRefresh={() => refetch()}
        updating={forecastStatus === "loading" || isFetchingForecasts || alertStatus === "loading" || isFetchingAlerts}
      />
      <Card.Body>
        <SlowLoader
          showSpinnerIfTrue={
            (forecastStatus === "loading" || isFetchingForecasts) &&
            (alertStatus === "loading" || isFetchingAlerts) &&
            eventTotals.length === 0
          }>
          {eventTotals.length > 0 ? (
            <>
              <Card.Text>Number of locations affected by each weather event:</Card.Text>
              <CardColumns style={{ columnWidth: "200px" }}>
                {eventTotals.map(([eventType, value]) => (
                  <Link
                    key={`dashboard-weather-${eventType}`}
                    to={{
                      pathname: "/Locations",
                      state: { filteredEvents: [eventType], filteredCollection: collectionId },
                    }}>
                    <DashboardSummaryCard eventType={eventType} value={value} />
                  </Link>
                ))}
              </CardColumns>
            </>
          ) : (
            <Card.Text>No locations exceed any of weather thresholds monitored</Card.Text>
          )}
        </SlowLoader>
      </Card.Body>
    </Card>
  );
}

export function DashboardSummaryCard({ eventType, value }: { eventType: string; value: number | string }) {
  return (
    <Card className="shadow-sm mb-3 position-static">
      <WeatherCardWrapper>
        <WeatherIcon eventType={eventType} iconClass="mx-2" iconStyle={{ width: "48", height: "48", flexShrink: 0 }} />
        <div className="d-flex flex-column">
          <Card.Title className="text-nowrap">
            <WeatherText eventType={eventType} />
          </Card.Title>
          <Card.Text>{value}</Card.Text>
        </div>
      </WeatherCardWrapper>
    </Card>
  );
}

const WeatherCardWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem;
`;
