import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import styled from "styled-components/macro";
import { UserProfileCard } from "../components/UserProfileCard";
import { ChangePasswordCard } from "../components/ChangePasswordCard";

export function UserProfilePage() {
  const [showProfile, switchProfile] = useState(true);

  return (
    <Container className="p-3">
      <Row>
        <Col>
          <Card>
            <Card.Header>User Profile</Card.Header>
            <Card.Body>
              <Switch
                onClick={() => switchProfile(true)}
                className={"btn-link pointer" + (showProfile ? " active" : "")}>
                Profile
              </Switch>
              <Switch
                onClick={() => switchProfile(false)}
                className={"btn-link pointer" + (showProfile ? "" : " active")}>
                Change Password
              </Switch>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={9} md={7} className="py-3 py-sm-0">
          {showProfile ? <UserProfileCard /> : <ChangePasswordCard />}
        </Col>
      </Row>
    </Container>
  );
}

const Switch = styled.p`
  &.active {
    font-weight: bold;
  }
`;

export default UserProfilePage;
