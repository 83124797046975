// this file is auto generated, do not edit it directly
module.exports = {
  "Auth": {
    "region": "us-west-2",
    "userPoolId": "us-west-2_0Q5ggKTi3",
    "userPoolWebClientId": "4oqfqki4ghntmrpic9n0on633m",
    "mandatorySignIn": true,
    "authenticationFlowType": "USER_PASSWORD_AUTH"
  },
  "ApiConfig": {
    "host": "api.signal4d.com"
  },
  "AppConfig": {
    "name": "Route Watch"
  }
}