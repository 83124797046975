import React, { useContext } from "react";
import { AppContext } from "App";
import type { Icon } from "icon-library";
import Home from "pages/Home";
import UserProfilePage from "pages/UserProfile";
import Logout from "pages/Logout";
import * as H from "history";
import Dashboard from "pages/Dashboard";
const LocationsPage = React.lazy(() => import("pages/Locations"));
const MapPage = React.lazy(() => import("pages/MapPage"));
const RoutesPage = React.lazy(() => import("pages/Routes"));
const FlightsPage = React.lazy(() => import("pages/Flights"));

export type RouteType = {
  path: string;
  name: string;
  exact?: boolean;
  location?: H.Location;
  icon: Icon;
  page?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  subMenu?: string;
  groups?: string[];
};
//     groups: ["dashboard-users", "dashboard-admins", "dashboard-weatheralerts", "dashboard-weatherforecast", "dashboard-traffic"],

export const routeList: RouteType[] = [
  { path: "/", name: "Home", exact: true, icon: "home", page: Home },
  {
    path: "/dashboard",
    name: "Dashboard",
    exact: true,
    icon: "chart-bar",
    page: Dashboard,
    groups: ["dashboard-users"],
  },
  {
    path: "/locations",
    name: "Locations",
    exact: true,
    icon: "map-marker-alt",
    page: LocationsPage,
    groups: ["dashboard-devices"],
  },
  {
    path: "/routes",
    name: "Routes",
    exact: true,
    icon: "route",
    page: RoutesPage,
    groups: ["dashboard-routeintersect"],
  },
  {
    path: "/flights",
    name: "Flights",
    exact: true,
    icon: "fxDroneRadar",
    page: FlightsPage,
    groups: ["dashboard-flights", "flightwatch-users"],
  },
  {
    path: "/map",
    name: "Map",
    exact: true,
    icon: "map",
    page: MapPage,
    subMenu: "sm-Map",
    groups: ["dashboard-users", "flightwatch-users"],
  },
  { path: "/userprofile", name: "User Profile", exact: true, icon: "user-cog", page: UserProfilePage },
  { path: "/logout", name: "Logout", exact: false, icon: "user-slash", page: Logout },
];

export const useAuthRouteList = () => {
  const { user } = useContext(AppContext);
  const { groups = [] } = user ?? {};
  return routeList.filter((r) => !r.groups || r.groups.some((g) => groups && groups.includes(g)));
};
