import polyline from "@mapbox/polyline";
import { Feature, FeatureCollection, LineString } from "geojson";
import { getBbox } from "utils/getBbox";

interface Maneuver {
  bearing_after: number;
  bearing_before: number;
  location: [number, number] | number[];
  modifier?: string;
  type: string;
  instruction: string;
}

interface Intersection {
  out?: number;
  in?: number;
  entry: boolean[];
  bearings: number[];
  location: [number, number] | number[];
}

export interface RouteStep {
  driving_side: string;
  geometry: string;
  mode: string;
  maneuver: Maneuver;
  duration: number;
  distance: number;
  name: string;
  lineString?: Feature<LineString> | FeatureCollection<LineString>;
  intersections?: Intersection[];
  weight?: never;
  ref?: string;
}

export interface RouteLeg {
  annotation: {
    congestion: string[];
  };
  summary: string;
  duration: number;
  steps: RouteStep[];
  distance: number;
  weight?: never;
}

export interface RouteObject {
  geometry: string;
  lineString?: Feature<LineString>;
  geohashes?: string[];
  legs: RouteLeg[];
  duration: number;
  distance: number;
}

export interface Waypoint {
  distance?: number; // meters
  name?: string;
  location: [number, number]; // [longitude, latitude]
}

export interface Route {
  routeId: string;
  routes: RouteObject[];
  waypoints: Waypoint[];
  description?: string;
  collectionId?: string;
  received?: string; // ISO date string
  displayOnMap?: boolean;
}

export type RouteProps = Omit<Route, "routeId">;

export type RouteReference = Omit<Route, "routes">;

/**
 * The event data included in the "route" event fired by Mapbox Directions
 */
export interface MBDirectionsRouteEvent {
  route: RouteObject[];
}

export interface RouteLineWithId {
  routeId: string;
  routeLine: LineString;
}

export const optimizeRouteForRequest = (route: Route): Route => {
  return {
    routeId: route.routeId,
    collectionId: route.collectionId,
    waypoints: route.waypoints,
    description: route.description,
    displayOnMap: route.displayOnMap,
    routes: route.routes.map((innerRoute) => ({
      geometry: innerRoute.geometry,
      legs: innerRoute.legs.map((leg) => ({
        distance: leg.distance,
        duration: leg.duration,
        steps: leg.steps.map((step) => ({
          distance: step.distance,
          duration: step.duration,
          geometry: step.geometry,
          maneuver: step.maneuver,
          name: step.name,
          ref: step.ref,
        })),
      })),
    })),
  } as Route;
};

export interface RouteFeatureProperties {
  /** Helpful for filtering from MapBox */
  type: "route";
  /** Feature ID for mapbox */
  id: string;
  routeId: string;
  description?: string;
  collectionId?: string;
  received?: string; // ISO date string
  displayOnMap?: boolean;
}

export function buildRouteGeoJson<RouteWithOtherData extends Route>(
  routes: RouteWithOtherData[],
  featureCollectionId?: string,
): FeatureCollection<LineString, RouteFeatureProperties> {
  const routeFeatures: Feature<LineString, RouteFeatureProperties>[] = routes.map((route) => {
    // cache the line string on the inner route object
    if (!route.routes[0].lineString) {
      route.routes[0].lineString = {
        type: "Feature",
        properties: {},
        geometry: polyline.toGeoJSON(route.routes[0].geometry),
      };
    }
    if (!route.routes[0].lineString.geometry.bbox) {
      route.routes[0].lineString.geometry.bbox = getBbox(route.routes[0].lineString);
    }

    const { routes, ...theRest } = route;

    return {
      type: "Feature" as const,
      geometry: route.routes[0].lineString.geometry,
      id: route.routeId,
      properties: {
        ...theRest,
        id: route.routeId,
        type: "route",
      },
    } as Feature<LineString, RouteFeatureProperties>;
  });

  return {
    type: "FeatureCollection",
    features: routeFeatures,
    id: featureCollectionId,
  } as FeatureCollection<LineString, RouteFeatureProperties>;
}
