/* eslint-disable @typescript-eslint/no-explicit-any */

export function getAuthStorage(): Pick<Storage, "setItem" | "getItem" | "removeItem" | "clear"> {
  try {
    window.localStorage.setItem("signal4d.test", "1");
    window.localStorage.removeItem("signal4d.test");
    // we know local storage is working
    return window.localStorage;
  } catch (error) {
    console.warn("Cannot use localstorage; falling back to memory storage");
    return MemoryStorage;
  }
}

let dataMemory: {
  [key: string]: string;
} = {};

export class MemoryStorage {
  static setItem(key: string, value: any) {
    dataMemory[key] = value;
    return dataMemory[key];
  }

  static getItem(key: string) {
    return Object.prototype.hasOwnProperty.call(dataMemory, key) ? dataMemory[key] : null;
  }

  static removeItem(key: string) {
    return delete dataMemory[key];
  }

  static clear() {
    dataMemory = {};
    return dataMemory;
  }
}
