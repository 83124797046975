import React from "react";
import { weatherText } from "models/forecast";
import { Dictionary } from "utils";
import { S4dIcon } from "icon-library";
import styled from "styled-components/macro";

export const WeatherType = ({
  eventType,
  showIcon = true,
  iconClass,
  iconStyle,
}: {
  eventType: string;
  showIcon?: boolean;
  iconClass?: string;
  iconStyle?: React.CSSProperties;
}) => (
  <div className="text-nowrap">
    {showIcon && iconType[eventType] && (
      <WeatherIcon eventType={eventType} iconClass={iconClass} iconStyle={iconStyle} />
    )}
    <WeatherText eventType={eventType} />
  </div>
);

export const WeatherText = ({ eventType }: { eventType: string }) => <>{weatherText(eventType).title}</>;

export const WeatherIcon = ({
  eventType,
  iconClass = "",
  iconStyle = {},
}: {
  eventType: string;
  iconClass?: string;
  iconStyle?: React.CSSProperties;
}) => <Icon className={"icon " + iconClass} icon={iconType[eventType]} style={iconStyle} />;

export const iconType: Dictionary<string, string> = {
  highWind: "wxWind",
  highGusts: "wxWindGusts",
  temperatureLow: "wxTempLow",
  temperatureHigh: "wxTempHigh",
  snow: "wxSnow",
  heavyRain: "wxRain",
  severe: "wxTornado",
  flood: "wxFlood",
  winter: "wxWinter",
  tropical: "wxTropical",
  other: "wxOther",
};

export const Icon = styled(({ bgColor, ...rest }) => <S4dIcon {...rest} />)<{ bgColor: string }>`
  margin-right: 2px;
  width: 30px;
  height: 20px;
`;
